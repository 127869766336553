export default class ProductsModel {
    static BaseForm() {
        return {
            name: '',
            description: "",
            price: 100,

        }
    }

}